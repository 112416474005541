import TemplateA from "../template_a";

export default function HuarazPage(){
    const url= "https://www.dosgardeniastours.com/destinos/huaraz";
    const description = "Emprende una aventura en Huaraz, la capital del trekking en Perú, con impresionantes paisajes montañosos, lagunas cristalinas, contundentes platos andinos y consejos para los amantes de la naturaleza";

    const content = {
        "banner": "/images/destinos/huaraz/huaraz.jpg",
        "document": {
          "name": "Programa Huaraz",
          "pdf": "/pdf/destinos/programa-huaraz.pdf"
        },
        "title": "Huaraz: La Capital del Andinismo en Perú",
        "subtitle": "Ubicada en la región de Áncash, Huaraz es el destino perfecto para los amantes de la naturaleza y la aventura. Conocida como la Capital del Andinismo, esta ciudad es la puerta de entrada a la imponente Cordillera Blanca, una de las cadenas montañosas más altas del mundo, repleta de paisajes de ensueño y retos inolvidables.",
        "sections": [
          {
            "title": "Mejor época para viajar a Huaraz",
            "content": "\nDe mayo a septiembre: Temporada seca, ideal para trekking y deportes de aventura.\n Junio y julio: Días soleados y noches frescas, perfectos para explorar al máximo.\n Evita noviembre a marzo: Las lluvias pueden dificultar el acceso a los atractivos turísticos."
          },
          {
            "title": "Lugares imperdibles en Huaraz",
            "subsections": [
              {
                "title": "Montañas, lagunas y trekking",
                "image": "/images/destinos/huaraz/nevado-pastoruri.jpeg",
                "list": [
                  {
                    "name": "Laguna 69",
                    "description": "Un espejo turquesa rodeado de montañas nevadas."
                  },
                  {
                    "name": "Nevado Pastoruri",
                    "description": "Ideal para quienes quieren disfrutar de la nieve en Perú."
                  },
                  {
                    "name": "Laguna Parón",
                    "description": "Un paisaje de postal en el corazón de la Cordillera Blanca."
                  }
                ]
              },
              {
                "title": "Parques y reservas naturales",
                "image": "/images/destinos/huaraz/nevado-huascaran.jpeg",
                "list": [
                  {
                    "name": "Parque Nacional Huascarán",
                    "description": "Patrimonio Natural de la Humanidad y hogar de glaciares imponentes."
                  },
                  {
                    "name": "Llanganuco",
                    "description": "Dos lagunas de aguas color esmeralda rodeadas de picos nevados."
                  }
                ]
              },
              {
                "title": "Cultura e historia",
                "image": "/images/destinos/huaraz/chavin-de-huantar.jpeg",
                "list": [
                  {
                    "name": "Chavín de Huántar",
                    "description": "Complejo arqueológico que ofrece un vistazo a las culturas preincaicas."
                  },
                  {
                    "name": "Museo Arqueológico de Áncash",
                    "description": "Perfecto para conocer la historia y tradiciones de la región."
                  }
                ]
              },
              {
                "title": "Deportes de aventura",
                "image": "/images/destinos/huaraz/trekking.jpg",
                "list": [
                  {
                    "name": "Escalada en roca",
                    "description": "Rutas desafiantes para expertos y principiantes."
                  },
                  {
                    "name": "Ciclismo de montaña y parapente",
                    "description": "Aventura con vistas panorámicas incomparables."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Huaraz",
            "content": "Huaraz deleita con una cocina llena de tradición y sabor:",
            "list": [
              {
                "name": "Pachamanca",
                "description": "Carnes y vegetales cocidos bajo tierra."
              },
              {
                "name": "Cuchicanca",
                "description": "Cerdo al horno con una sazón única."
              },
              {
                "name": "Llunca con gallina",
                "description": "Sopa de trigo perfecta para combatir el frío."
              },
              {
                "name": "Calientito",
                "description": "Bebida reconfortante ideal para las noches frías."
              }
            ]
          },
          {
            "title": "Consejos para visitar Huaraz",
            "list": [
              {
                "name": "Aclimatación",
                "description": "Huaraz está a más de 3,000 m.s.n.m.; evita actividades físicas intensas al llegar."
              },
              {
                "name": "Ropa adecuada",
                "description": "Lleva prendas térmicas y cómodas, esenciales para las excursiones."
              },
              {
                "name": "Protección solar",
                "description": "Asegúrate de usar bloqueador y mantenerte hidratado."
              },
              {
                "name": "Guías locales",
                "description": "Contrata expertos para trekking y tours; esto garantiza seguridad y una experiencia enriquecedora."
              },
              {
                "name": "Reserva anticipada",
                "description": "Hospedajes y tours se llenan rápido en temporada alta."
              }
            ]
          },
          {
            "title": "¿Por qué visitar Huaraz?",
            "content": "Huaraz es el paraíso de los aventureros y un tesoro de la naturaleza. Desde la majestuosidad de la Cordillera Blanca hasta las aguas cristalinas de la Laguna 69, cada rincón te conectará con lo mejor del Perú.\n¡Atrévete a explorar Huaraz y vive una experiencia única que quedará grabada para siempre! 🏔️✨"
          },
          {
            "title": "Laguna 69",
            "image": "/images/destinos/huaraz/laguna-69.jpg",
            "content": "La Laguna 69, en el corazón de la Cordillera Blanca, deslumbra con sus aguas azul intenso rodeadas de imponentes glaciares. Ideal para aventureros que buscan paisajes de ensueño tras una emocionante caminata. \n¡Un espectáculo natural que no puedes perderte!"
          }
        ],
        "itinerary": [
          {
            "day": 1,
            "title": "Laguna Llanganuco + Yungay",
            "description": [
              "Recorrido por el Callejón de Huaylas y Carhuaz para disfrutar de helados tradicionales.",
              "Visita al Campo Santo de Yungay y el Cristo de Yungay.",
              "Finalizamos en la Laguna Llanganuco, ubicada en el Parque Nacional Huascarán, ideal para fotos y paisajes únicos."
            ]
          },
          {
            "day": 2,
            "title": "Nevado Pastoruri",
            "description": [
              "Exploración de Ticapampa, Laguna Patococha y fuente de agua mineral con gas natural.",
              "Observamos el bosque de Puya Raimondi, el Glaciar Pastoruri y la Laguna Congelada."
            ]
          },
          {
            "day": 3,
            "title": "Chavín de Huántar",
            "description": [
              "Visita a Laguna Querococha, el mirador del 'Mapa del Perú' y el Túnel de Cawish (4530 m.s.n.m.).",
              "Recorrido por el Complejo Arqueológico de Chavín de Huántar y su museo de sitio."
            ]
          },
          {
            "day": 4,
            "title": "Trekking Laguna 69",
            "description": [
              "Iniciaremos desde Yurac Corral (3900 m.s.n.m.) con una caminata de 3 horas hasta la impresionante Laguna 69, de aguas turquesa.",
              "En el trayecto, se visitará nuevamente la Laguna Llanganuco."
            ]
          }
        ],
       
      }
      

    return (
        <TemplateA data={content} place={"Huaraz"} title={content.title} canonicalUrl={url} description={description}  />
    )
}