import TemplateB from "../template_b";

export default function ChachapoyasPage(){
    const url = "https://www.dosgardeniastours.com/destinos/chachapoyas";
    const description = "Explora Chachapoyas, la tierra de las nubes, con sitios impresionantes como la fortaleza de Kuélap, la catarata de Gocta, tradiciones locales y consejos útiles para tu aventura.";
    
    const content = {
        "banner": "/images/destinos/chachapoyas/chachapoyas.jpg",
        "document": {
          "name": "Programa Chachapoyas",
          "pdf": "/pdf/destinos/programa-chachapoyas.pdf"
        },
        "title": "Chachapoyas: La Ciudad de las Nubes y Cuna de la Cultura Preincaica",
        "subtitle": "Ubicada en el corazón de la región Amazonas, Chachapoyas es un destino que combina paisajes espectaculares, historia milenaria y biodiversidad única. \n\n Conocida como la Ciudad de las Nubes, es una puerta de entrada a la cultura de los Chachapoyas, ofreciendo experiencias inolvidables para los amantes de la historia y la naturaleza.",
        "sections": [
          {
            "title": "Mejor época para visitar Chachapoyas",
            "content": "De abril a noviembre: Temporada seca, perfecta para trekking y exploración.\nEvita de diciembre a marzo: Lluvias intensas pueden complicar los accesos.\nBonus: De junio a agosto, el clima es ideal para visitar la Fortaleza de Kuélap y las Cataratas de Gocta."
          },
          {
            "title": "Lugares imperdibles en Chachapoyas",
            "subsections": [
              {
                "title": "Historia y arqueología",
                "image":"/images/destinos/chachapoyas/revash.jpeg",
                "list": [
                  {
                    "name": "Fortaleza de Kuélap",
                    "description": "La 'Machu Picchu del Norte', una ciudadela milenaria con vistas impresionantes."
                  },
                  {
                    "name": "Mausoleos de Revash",
                    "description": "Tumbas construidas en acantilados que desafían la imaginación."
                  },
                  {
                    "name": "Museo Leymebamba",
                    "description": "Hogar de más de 200 momias Chachapoyas y artefactos preincaicos."
                  }
                ]
              },
              {
                "title": "Maravillas naturales",
                "image":"/images/destinos/chachapoyas/gocta.jpeg",
                "list": [
                  {
                    "name": "Cataratas de Gocta",
                    "description": "Una de las más altas del mundo, con una caminata rodeada de selva."
                  },
                  {
                    "name": "Sarcófagos de Karajía",
                    "description": "Monumentos funerarios únicos incrustados en montañas."
                  },
                  {
                    "name": "Caverna de Quiocta",
                    "description": "Un paraíso subterráneo lleno de estalactitas y estalagmitas."
                  }
                ]
              },
              {
                "title": "Aventuras en la selva alta",
                "image":"/images/destinos/chachapoyas/rio-abiseo.jpg",
                "list": [
                  {
                    "name": "Laguna de los Cóndores",
                    "description": "Descubre un refugio natural con tumbas ancestrales."
                  },
                  {
                    "name": "Reserva Nacional Río Abiseo",
                    "description": "Patrimonio de la Humanidad que combina biodiversidad y arqueología."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Chachapoyas",
            "content": "Disfruta de la fusión de sabores andinos y selváticos:",
            "list": [
              {
                "name": "Juane de yuca",
                "description": "Arroz envuelto en hojas de bijao con un toque local."
              },
              {
                "name": "Cecina con tacacho",
                "description": "Carne ahumada acompañada de plátano amasado."
              },
              {
                "name": "Caldo de gallina regional",
                "description": "Reconfortante y lleno de sabor."
              },
              {
                "name": "Licor de mora",
                "description": "Un must para los amantes de bebidas artesanales."
              }
            ]
          },
          {
            "title": "Consejos para tu visita",
            "list": [
              {
                "name": "Prepárate para el clima",
                "description": "Lleva ropa ligera e impermeable; el clima puede cambiar rápidamente."
              },
              {
                "name": "Apoya al turismo local",
                "description": "Contrata guías para explorar Kuélap o Gocta y contribuye al desarrollo de la comunidad."
              },
              {
                "name": "Planifica con anticipación",
                "description": "Reserva tours y alojamiento durante la temporada alta (junio-agosto)."
              },
              {
                "name": "Disfruta el viaje terrestre",
                "description": "Los paisajes hacia Chachapoyas son parte de la experiencia."
              }
            ]
          },
          {
            "title": "¿Por qué visitar Chachapoyas?",
            "content": "Chachapoyas tiene todo lo que un viajero busca: arqueología, aventura y naturaleza virgen. Desde la imponente Fortaleza de Kuélap hasta las majestuosas Cataratas de Gocta, este destino combina historia, cultura y paisajes únicos.\n\n¡Descubre por qué Chachapoyas es una joya escondida que todo amante de la historia y la naturaleza debe visitar! 🌿"
          },
          {
            "title": "Kuélap",
            "image": "/images/destinos/chachapoyas/kuelap.jpg",
            "content": "Explora Kuélap, la imponente fortaleza de los Chachapoyas, envuelta en misticismo y naturaleza. Sus majestuosas murallas y paisajes andinos te transportarán al pasado. ¡Descubre el secreto mejor guardado de la selva alta!"
          }
        ],
        "itinerary": [
          {
            "day": 1,
            "title": "Tour por Chachapoyas",
            "description": [
              "Explora el centro histórico, calles coloniales y el mirador Luya Urco para vistas panorámicas.",
              "Degusta platos típicos como el juane."
            ]
          },
          {
            "day": 2,
            "title": "Teleférico y Kuélap",
            "description": [
              "Visita la fortaleza de Kuélap, con sus imponentes muros y vistas espectaculares.",
              "Incluye teleférico y caminata moderada."
            ]
          },
          {
            "day": 3,
            "title": "Catarata Gocta",
            "description": [
              "Caminata al bosque nuboso para descubrir la catarata Gocta, una de las más altas del mundo.",
              "Observa flora y fauna local."
            ]
          },
          {
            "day": 4,
            "title": "Traslado al Aeropuerto",
            "description": [
              "Regreso según horario de vuelo."
            ]
          }
        ],
       
      }
      

    return (
        <TemplateB  data={content} place={"Chachapoyas"} title={content.title} canonicalUrl={url} description={description}   />
    )
}