import TemplateA from "../template_a";

export default function CartagenaPage(){
    const url = "https://www.dosgardeniastours.com/destinos/cartagena";
    const description = "Sumérgete en Cartagena, la joya caribeña de Colombia, con su colorido centro histórico, vibrante vida nocturna, deliciosa gastronomía y consejos para disfrutar de su encanto tropical";
    
    const content={
        "banner":"/images/destinos/cartagena/conoce-cartagena.jpeg",
        "document":{
            name:"Programa Cartagena",
            pdf: "/pdf/destinos/programa-cartagena.pdf"
        },
        "title": "Cartagena: La Joya del Caribe Colombiano",
        "subtitle": "Cartagena de Indias, ubicada en la costa norte de Colombia, es un destino único que combina historia, cultura y playas paradisíacas. Con su encantador centro histórico, murallas coloniales y sus islas caribeñas, esta ciudad es ideal para aquellos que buscan romance, aventura y descanso.",
        "sections": [
            {
            "title": "Mejor época para viajar a Cartagena",
            "content": "La mejor época para visitar Cartagena es durante la temporada seca, con clima cálido y soleado:\n\n Diciembre a abril: Clima perfecto para disfrutar de sus playas y explorar su patrimonio histórico.\n Evitar: Octubre y noviembre debido a la probabilidad de lluvias.\n Temporada alta: Diciembre, enero y Semana Santa."
            },
            {
            "title": "Destinos turísticos imprescindibles en Cartagena",
            "subsections": [
                {
                "image":"/images/destinos/cartagena/amurallada.webp",
                "title": "Centro Histórico y Patrimonio Colonial",
                "list": [
                    {
                    "name": "Ciudad Amurallada",
                    "description": "Descubre su arquitectura colonial y calles llenas de historia."
                    },
                    {
                    "name": "Castillo de San Felipe de Barajas",
                    "description": "Impresionante fortaleza que ofrece vistas panorámicas de la ciudad."
                    },
                    {
                    "name": "Plaza Santo Domingo",
                    "description": "Un lugar encantador con restaurantes y esculturas icónicas."
                    },
                    {
                    "name": "Torre del Reloj",
                    "description": "Puerta de entrada al centro histórico y uno de los símbolos de la ciudad."
                    }
                ]
                },
                {
                  "image":"/images/destinos/cartagena/playas.jpg",
                "title": "Playas y Experiencias Caribeñas",
                "list": [
                    {
                    "name": "Islas del Rosario",
                    "description": "Un paraíso de aguas cristalinas perfecto para el snorkeling."
                    },
                    {
                    "name": "Playa Blanca (Barú)",
                    "description": "Famosa por su arena blanca y aguas turquesas."
                    },
                    {
                    "name": "Bocagrande",
                    "description": "Una mezcla de playas, tiendas y rascacielos modernos."
                    }
                ]
                },
                {
                  "image":"/images/destinos/cartagena/getsemani.webp",
                "title": "Cultura y Tradiciones",
                "list": [
                    {
                    "name": "Getsemaní",
                    "description": "Un barrio vibrante con arte callejero y una vida nocturna única."
                    },
                    {
                    "name": "Museo del Oro Zenú",
                    "description": "Explora la riqueza cultural de los pueblos indígenas."
                    },
                    {
                    "name": "Danzas folclóricas",
                    "description": "Sumérgete en las tradiciones culturales de la región."
                    }
                ]
                }
            ]
            },
            {
            "title": "Gastronomía Cartagenera: Sabores del Caribe",
            "content": "La gastronomía de Cartagena ofrece una mezcla de sabores frescos y auténticos del Caribe colombiano. Algunos de los platos y bebidas más destacados son:",
            "list": [
                {
                "name": "Arepas de huevo",
                "description": "Arepas fritas rellenas con huevo y carne."
                },
                {
                "name": "Cazuela de mariscos",
                "description": "Un guiso cremoso con una variedad de mariscos frescos."
                },
                {
                "name": "Posta cartagenera",
                "description": "Carne guisada en una salsa dulce y sabrosa."
                },
                {
                "name": "Cocadas",
                "description": "Dulces tradicionales de coco."
                },
                {
                "name": "Limonada de coco",
                "description": "Bebida refrescante y tropical, ideal para el clima cálido."
                }
            ]
            },
            {
            "title": "Consejos para visitar Cartagena",
            "list": [
                {
                "name": "Ropa ligera",
                "description": "Lleva ropa fresca, bloqueador solar y sombrero para protegerte del intenso sol caribeño."
                },
                {
                "name": "Explora temprano",
                "description": "Las temperaturas son más agradables por la mañana para recorrer el centro histórico."
                },
                {
                "name": "Reserva tours",
                "description": "Contrata con antelación excursiones a las Islas del Rosario y Playa Blanca."
                },
                {
                "name": "Evita vendedores ambulantes",
                "description": "Sé firme si no estás interesado en los productos que ofrecen en zonas turísticas."
                },
                {
                "name": "Seguridad",
                "description": "Aunque Cartagena es segura, cuida tus pertenencias, especialmente en lugares concurridos."
                }
            ]
            },
            {
            "title": "¿Por qué visitar Cartagena?",
            "content": "Cartagena ofrece una experiencia inolvidable con su mezcla de historia, cultura y naturaleza. Desde paseos por la Ciudad Amurallada hasta días relajantes en las Islas del Rosario, esta ciudad tiene todo lo que necesitas para unas vacaciones perfectas. ¡Descubre por qué Cartagena de Indias es el tesoro del Caribe colombiano y vive unas vacaciones inolvidables! 🏖️✨"
            },
            {
                "image":"/images/destinos/cartagena/san-felipe.jpg",
                "title": "Castillo de San Felipe",
                "content": "El Castillo de San Felipe en Cartagena 🏰 es una fortaleza imponente que te lleva al pasado colonial de Colombia. \nCon vistas espectaculares al mar y una historia fascinante, es el lugar perfecto para explorar la arquitectura militar y disfrutar de un paisaje único 🌊⚔️. \n¡No te lo puedes perder!"
              }
              
        ],
          
          
        "itinerary": [
          {
            "day": 1,
            "title": "Llegada a Cartagena",
            "description": [
              "Arribo a Cartagena.",
              "Traslado al hotel y registro."
            ]
          },
          {
            "day": 2,
            "title": "Ciudad + Castillo de San Felipe",
            "description": [
              "Recorrido por Cartagena, visitando Bocagrande, el barrio de Manga y el Castillo de San Felipe de Barajas.",
              "Caminata por el centro histórico y visita al Museo de la Esmeralda.",
              "Tarde libre."
            ]
          },
          {
            "day": 3,
            "title": "Playa",
            "description": [
              "Traslado en lancha rápida a la isla para disfrutar de sus playas y fauna marina.",
              "Almuerzo típico incluido."
            ]
          },
          {
            "day": 4,
            "title": "Regreso",
            "description": [
              "Desayuno.",
              "Traslado al aeropuerto para el vuelo de regreso."
            ]
          }
        ],
       
      }
      

        
    

    return (
        <TemplateA data={content} place="Cartagena" title={content.title} canocnicalUrl={url} description={description} />
    )
}