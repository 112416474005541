import TemplateB from "../template_b";

export default function MexicoPage(){
    const url = "https://www.dosgardeniastours.com/destinos/mexico";
    const description = "Recorre los paisajes diversos de México, su rica historia, deliciosa gastronomía, monumentos icónicos y consejos esenciales para una experiencia cultural inmersiva";



    const content = 
    {
        "banner":"/images/destinos/mexico/conoce-mexico.jpg",
        "document":{
            "name":"Programa Mexico",
            "pdf":"/pdf/destinos/programa-mexico.pdf"
        },
        "title": "México: Un Viaje Inolvidable a la Cultura, Historia y Paisajes Naturales",
        "subtitle": "México es un destino lleno de contrastes: desde civilizaciones antiguas hasta ciudades modernas, con costas paradisíacas, montañas y desiertos. Su historia rica, tradiciones vibrantes y gastronomía mundialmente conocida hacen de México un lugar único para todos los viajeros.",
        "sections": [
          {
            "title": "Mejor época para viajar a México",
            "content": "La época ideal para viajar depende de la región que quieras visitar:\n\n- Playa y costa (Cancún, Riviera Maya, Los Cabos): Noviembre a abril, clima seco y soleado.\n- Centro de México (Ciudad de México, Puebla, Oaxaca): Octubre a mayo, temperaturas agradables y menos lluvias.\n- Norte y desierto (Sonora, Baja California): Marzo a junio, evitando el calor extremo del verano.\n- Tierras altas (San Cristóbal de las Casas, Monterrey): Marzo a mayo y septiembre a noviembre."
          },
          {
            "title": "Destinos turísticos imprescindibles en México",
            "subsections": [
              {
                "title": "Ciudad de México: El Corazón Cultural",
                "image": "/images/destinos/mexico/zocalo.jpeg",
                "list": [
                  {
                    "name": "Zócalo y Centro Histórico",
                    "description": "El corazón de la capital con arquitectura colonial y tradición cultural."
                  },
                  {
                    "name": "Museos",
                    "description": "Incluyen el Museo Nacional de Antropología y el Museo Frida Kahlo."
                  },
                  {
                    "name": "Teotihuacán",
                    "description": "Las impresionantes Pirámides del Sol y la Luna."
                  },
                  {
                    "name": "Xochimilco",
                    "description": "Coloridos paseos en trajinera por canales históricos."
                  }
                ]
              },
              {
                "title": "Riviera Maya: Sol y Playas",
                "image": "/images/destinos/mexico/rivera-maya.jpg",
                "list": [
                  {
                    "name": "Cancún, Tulum, Playa del Carmen",
                    "description": "Playas paradisíacas y vibrante vida nocturna."
                  },
                  {
                    "name": "Cenotes",
                    "description": "Pozos naturales ideales para nadar y explorar."
                  }
                ]
              },
              {
                "title": "Oaxaca: Cultura y Tradición",
                "image": "/images/destinos/mexico/oaxaca.jpg",
                "list": [
                  {
                    "name": "Monte Albán",
                    "description": "Ruinas arqueológicas de la antigua civilización zapoteca."
                  },
                  {
                    "name": "Hierve el Agua",
                    "description": "Formaciones naturales con vistas impresionantes."
                  },
                  {
                    "name": "Guelaguetza",
                    "description": "Festival cultural que celebra las tradiciones indígenas."
                  },
                  {
                    "name": "Gastronomía",
                    "description": "Disfruta del mole, tlayudas y otros platos típicos."
                  }
                ]
              },
              {
                "title": "Yucatán: El Mundo Maya",
                "image": "/images/destinos/mexico/chichen-itza.jpg",
                "list": [
                  {
                    "name": "Chichén Itzá",
                    "description": "Una de las Nuevas Maravillas del Mundo."
                  },
                  {
                    "name": "Mérida",
                    "description": "La ciudad blanca, llena de cultura y tradición."
                  },
                  {
                    "name": "Uxmal",
                    "description": "Ruinas mayas menos concurridas y fascinantes."
                  },
                  {
                    "name": "Cenotes",
                    "description": "Explora los cenotes de la región, ideales para refrescarse."
                  }
                ]
              },
              {
                "title": "Baja California Sur: Mar y Montañas",
                "image": "/images/destinos/mexico/los-cabos.jpg",
                "list": [
                  {
                    "name": "Los Cabos",
                    "description": "Famoso por sus paisajes desérticos junto al mar."
                  },
                  {
                    "name": "La Paz",
                    "description": "Playas tranquilas y excursiones para nadar con tiburones ballena."
                  },
                  {
                    "name": "Valle de Guadalupe",
                    "description": "Una región vinícola de renombre internacional."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía Mexicana: Un Festival de Sabores",
            "content": "México es conocido por su cocina deliciosa y única. No te pierdas:",
            "list": [
              {
                "name": "Tacos al Pastor",
                "description": "Tacos de cerdo marinados con especias y piña."
              },
              {
                "name": "Pozole",
                "description": "Un guiso tradicional con maíz y carne."
              },
              {
                "name": "Tamales",
                "description": "Masa de maíz rellena, cocida en hojas de maíz o plátano."
              },
              {
                "name": "Guacamole",
                "description": "Dip cremoso de aguacate con tomate y limón."
              },
              {
                "name": "Chiles en Nogada",
                "description": "Plato patriótico relleno de carne, cubierto con salsa de nuez."
              },
              {
                "name": "Tequila y Mezcal",
                "description": "Licores emblemáticos de México para cualquier celebración."
              }
            ]
          },
          {
            "title": "Consejos para visitar México",
            "list": [
              {
                "name": "Clima variado",
                "description": "Prepara ropa ligera para playas y algo abrigado para montañas."
              },
              {
                "name": "Seguridad",
                "description": "Consulta las recomendaciones locales y toma precauciones."
              },
              {
                "name": "Transporte",
                "description": "Aprovecha la red de transporte público, pero alquila coche para zonas alejadas."
              },
              {
                "name": "Respeto cultural",
                "description": "Conoce las costumbres locales para una experiencia más enriquecedora."
              }
            ]
          },
          {
            "title": "¿Por qué visitar México?",
            "content": "México ofrece una mezcla única de ruinas mayas, playas caribeñas, pueblos mágicos y una gastronomía exquisita. Su gente, amable y hospitalaria, convierte a México en un destino imprescindible para todos los viajeros. ¡Explora todo lo que México tiene para ofrecer y vive una experiencia única! 🌞"
          },
          {
                "title": "Xochimilco",
                "image":"/images/destinos/mexico/xochimilco.jpg",
                "content": "Xochimilco, un paraíso de canales rodeado de coloridas trajineras, es un lugar único para disfrutar de la tradición mexicana.  Navega por sus aguas y sumérgete en la cultura, música y gastronomía local 🎶🌮.\n¡Un paseo imperdible en la Ciudad de México!"
          }
        ],

        "itinerary": [
            {
            "day": 1,
            "title": "Llegada a la Ciudad de México",
            "description": [
                "Recepción en el aeropuerto y traslado al hotel."
            ]
            },
            {
            "day": 2,
            "title": "Ciudad de México",
            "description": [
                "Desayuno y recorrido por el centro histórico: Zócalo, Palacio Nacional, Templo Mayor, Catedral Metropolitana y Bosque de Chapultepec.",
                "Tarde libre para explorar a tu ritmo."
            ]
            },
            {
            "day": 3,
            "title": "Basílica de Guadalupe / Pirámides de Teotihuacán",
            "description": [
                "Visita panorámica a la Plaza de las Tres Culturas.",
                "Recorrido por las Pirámides de Teotihuacán y el Templo de Quetzalpapálotl.",
                "Parada en la Basílica de Guadalupe.",
                "Tarde libre al regresar."
            ]
            },
            {
            "day": 4,
            "title": "Cuernavaca y Taxco",
            "description": [
                "Recorrido por Cuernavaca, visitando su catedral y el Palacio de Cortés.",
                "Visita a Taxco para conocer la parroquia de Santa Prisca y explorar sus calles.",
                "Tarde libre al regresar."
            ]
            },
            {
            "day": 5,
            "title": "Traslado de Salida",
            "description": [
                "Desayuno y traslado al aeropuerto para el vuelo de regreso."
            ]
            }
        ],


      }
      

    


    return(
        <TemplateB data={content} place="Mexico" title={content.title} canonicalUrl={url} description={description} />
    )
}