// src/App.js
import React, { createContext, useState } from "react";

import Home from "./pages/home";
import Aboutus from "./pages/aboutus";
import National from "./pages/national";
import International from "./pages/international";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Providers from "./providers/header";
import VPromo from "./pages/vpromo";
import Wedding from "./pages/wedding";
import ArequipaPage from "./pages/destinos/arequipa";
import CuscoPage from "./pages/destinos/cusco";
import CajamarcaPage from "./pages/destinos/cajamarca";
import TailandiaPage from "./pages/destinos/tailandia";
import MexicoPage from "./pages/destinos/mexico";
import CartagenaPage from "./pages/destinos/cartagena";
import { Helmet } from "react-helmet";
import IcaPage from "./pages/destinos/ica";
import HuarazPage from "./pages/destinos/huaraz";
import ChachapoyasPage from "./pages/destinos/chachapoyas";
import PunoPage from "./pages/destinos/puno";
import SelvaCentralPage from "./pages/destinos/selva-central";
import TarapotoPage from "./pages/destinos/tarapoto";
import HuancayaPage from "./pages/destinos/huancaya";

export const AppContext = createContext();
function App() {
	const [loading, setLoading] = useState(false);

	const handleLoading = (v) => {
		setLoading(v);
		if (v) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	};

	return (
		<>
		<Helmet>
			<title>Dos Gardenias Tours</title>
			<link rel="canonical" href={"https://www.dosgardeniastours.com/"} />

		</Helmet>
			<div id="home" className="relative">
				<Providers>
					<AppContext.Provider value={{ loading, handleLoading }}>
						{loading && (
							<div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
								<img
									src="/images/loading.gif"
									className="m-auto h-32"
									alt="plane animation"
								/>
							</div>
						)}
						<Router>
							<Routes>
								<Route exact path="/" element={<Home />} />
								<Route path="/nosotros" element={<Aboutus />} />
								<Route path="/vuelos-nacionales" element={<National />} />
								<Route path="/vuelos-internacionales" element={<International />} />
								<Route path="/viajes-promocion" element={<VPromo />} />
								<Route path="/bodas" element={<Wedding />} />								
								<Route path="/destinos/cusco" element={<CuscoPage />} />
								<Route path="/destinos/arequipa" element={<ArequipaPage />} />
								<Route path="/destinos/cajamarca" element={<CajamarcaPage />} />
								<Route path="/destinos/tailandia" element={<TailandiaPage />} />
								<Route path="/destinos/mexico" element={<MexicoPage />} />
								<Route path="/destinos/cartagena" element={<CartagenaPage />} />
								<Route path="/destinos/ica" element={<IcaPage />} />
								<Route path="/destinos/huaraz" element={<HuarazPage />} />
								<Route path="/destinos/chachapoyas" element={<ChachapoyasPage />} />
								<Route path="/destinos/puno" element={<PunoPage />} />
								<Route path="/destinos/selva-central" element={<SelvaCentralPage />} />
								<Route path="/destinos/tarapoto" element={<TarapotoPage />} />
								<Route path="/destinos/huancaya" element={<HuancayaPage />} />
								

							</Routes>
						</Router>
					</AppContext.Provider>
				</Providers>
			</div>
		</>
	);
}

export default App;
