import InfoItemB from "../components/info_item_b"
import TipItem from "../components/tip_item";

import { useScrollTo } from "../utils";

import Header from "../components/header";
import Footer from "../components/footer";
import Schedule from "../components/schedule";
import { REACT_APP_APP_NAME } from "../components/utils";
import { Helmet } from "react-helmet";

export default function TemplateB(props){
    const {data, title, canonicalUrl, description} = props;
    const place = props.place;
    
    const { elementRef, scrollToElement } = useScrollTo();
    
    const app_name = REACT_APP_APP_NAME;
    

    

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="description" content={description} />
                <meta property="og:site_name" content="DosGardeniasTours" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:title" content={data.title} />
                <meta property="og:description" content={description} />              
            </Helmet>
            <Header/>
            <div className="max-w-full font-poppins relative ">
                <div className="px-8 md:px-[8%] bg-[#9B9289] py-16  m-auto w-screen text-white whitespace-pre-line"> 
                    <h1 className="text-4xl text-center  w-full md:w-3/4  xl:w-96 m-auto font-ivymode font-bold ">
                    {data.title}
                    </h1>
                    <h2 className=" max-w-full  w-full px-10 xl:px-4  xl:max-w-3xl mx-auto mt-4 text-xl  text-center pt-10 pb-32">
                        {data.subtitle}
                    </h2>
                </div>
                <div className="max-w-full lg:max-w-7xl mx-auto">

                    
                    <div className="relative -mt-32 z-10 flex justify-center mx-auto  ">
                        
                        <img src={data.banner} alt={`${app_name}-descubre-${place}`}  className=" w-full md:w-auto max-w-4xl shadow-lg rounded-md "  />
                    </div>


                    <div className=" pt-12  px-8 xl:px-8  ">

                        <div className="flex justify-center space-x-4">
                            <button className="px-6 py-2 text-white bg-[#846f62] hover:bg-[#6c594e] rounded-md w-3/4  md:w-48" onClick={scrollToElement}>
                                VER ITINERARIO
                            </button>
                            <a target="_blank"
                                rel="noreferrer"
                                href={data.document.pdf}	
                                className="px-4 py-2 rounded-lg bg-[#846f62] hover:bg-[#6c594e] w-3/4 md:w-48  text-white text-center ">
                                DESCUBRE MÁS
                            </a>
                        </div>

                        
                        <div className="py-20 text-xl text-[#4b3e38] text-center">
                            <h2 className="text-2xl font-bold mb-4">{data.sections[0].title}</h2>
                            <h3 className="whitespace-pre-line">{data.sections[0].content}</h3>
                            
                        </div>

                        <div className="flex flex-col gap-y-10 md:gap-y-0  mb-20">
                            <h2 className="text-2xl font-bold text-center mb-10 ">{data.sections[1].title}</h2>
                            {data.sections[1].subsections.map((it,idx)=>                         
                                <InfoItemB place={"tarapoto"} data={it} key={`item-info-${idx}`} idx={idx} />
                            )}
                        </div>
                        
                        <div ref={elementRef}></div>
                        <Schedule  itinerary={data.itinerary} />   
                            
                        {data.sections.length === 6 &&<div id="special" className="my-10">
                            <div className=" w-full xl:max-w-7xl flex justify-center flex-col">
                                <h2 className="text-3xl font-bold text-center ">{data.sections[5].title}</h2>
                                <img alt={app_name+`-descubre-${place}`} src={data.sections[5].image} className="m-auto w-3/4 max-w-screen-xl mt-4 mb-8 px-0 md:px-2 xl:px-0" />                    
                            </div>
                            <div className="text-center">
                                <h3 className="whitespace-pre-line text-xl">{data.sections[5].content}</h3>
                            </div>
                        </div>}

                        <div id="food" className="my-14">
                            <h2 className="text-2xl font-bold text-center mb-10 ">{data.sections[2].title}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 xl:gap-10 my-10">
                            {data.sections[2].list.map((it,idx)=>                         
                                <TipItem place={place} data={it} key={`item-info-${idx}`} />
                            )}
                            </div>
                        </div>

                        <div id="tips" className="my-14">
                            <h2 className="text-2xl font-bold text-center mb-10 ">{data.sections[3].title}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-10 my-10 ">
                            {data.sections[3].list.map((it,idx)=>                         
                                <TipItem place={place} data={it} key={`item-info-${idx}`} />
                            )}
                            </div>
                        </div>
                        <div id="discovery" className="my-10">
                            <h2 className="text-center font-bold text-2xl">{data.sections[4].title}</h2>
                            <h4 className="py-4 text-xl text-center whitespace-pre-line ">
                                {data.sections[4].content}
                            </h4>
                            <div className="flex gap-x-10 flex-wrap gap-y-4 justify-center my-6">

                                <a 
                                    href={`https://wa.me/51917838795?text=${encodeURIComponent(`Hola, deseo más información sobre sus paquetes hacia ${place}`)}`}
                                    target="_blank"
                                    rel="noreferrer" className="text-center w-3/4 md:w-48 py-2 px-4 bg-dgtitle text-white rounded-lg hover:bg-dggreen" >RESERVA TU VIAJE</a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={data.document.pdf}									
                                    className="text-center w-3/4 md:w-48 py-2 px-4 bg-dgtitle text-white rounded-lg hover:bg-dggreen" 
                                >
                                        DESCUBRE MÁS
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Footer/>

        </>
    )
}