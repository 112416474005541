import TemplateB from "../template_b";

export default function IcaPage(){
    const  url = "https://www.dosgardeniastours.com/destinos/ica";
    
    const description = "Descubre Ica, un oasis de aventura en Perú, con emocionantes paseos en sandboard, las enigmáticas Líneas de Nazca, exquisitas catas de pisco y consejos para un viaje inolvidable";

    const content = 
    {
        "banner":"/images/destinos/ica/ica.jpg",
        "document":{
            "name":"Programa Ica",
            "pdf":"/pdf/destinos/programa-ica.pdf"
        },
        "title": "Ica: Tierra de Sol, Vino y Aventura en el Desierto",
        "subtitle": "Ubicada en la costa sur del Perú, Ica mezcla desiertos dorados, oasis encantadores y una rica tradición vitivinícola. Reconocida como la Capital del Pisco y el Vino, esta región ofrece una experiencia única que combina naturaleza, cultura y aventura.",
        "sections": [
          {
            "title": "Mejor época para viajar a Ica",
            "content": "Entre abril y noviembre: Clima agradable para disfrutar actividades al aire libre.\n\nDe diciembre a marzo: El calor puede ser más intenso, aunque las lluvias son poco comunes."
          },
          {
            "title": "Lugares imperdibles en Ica",
            "subsections": [
              {
                "title": "Naturaleza y Aventura",
                "image": "/images/destinos/ica/huacachina.jpeg",
                "list": [
                  {
                    "name": "Oasis de Huacachina",
                    "description": "Un paraíso rodeado de dunas, ideal para sandboarding y tubulares."
                  },
                  {
                    "name": "Reserva Nacional de Paracas",
                    "description": "Un santuario natural con paisajes espectaculares."
                  },
                  {
                    "name": "Islas Ballestas",
                    "description": "Hogar de pingüinos, lobos marinos y aves marinas únicas."
                  }
                ]
              },
              {
                "title": "Viñedos y Bodegas",
                "image": "/images/destinos/ica/viñedo.jpeg",
                "list": [
                  {
                    "name": "Bodegas icónicas",
                    "description": "Tacama, Vista Alegre y El Catador son imprescindibles."
                  },
                  {
                    "name": "Piscos y vinos",
                    "description": "Degusta productos locales en tours guiados."
                  }
                ]
              },
              {
                "title": "Cultura e Historia",
                "image": "/images/destinos/ica/candelabro.jpg",
                "list": [
                  {
                    "name": "Líneas de Nasca",
                    "description": "Enigmáticos geoglifos que desafían el tiempo y la imaginación."
                  },
                  {
                    "name": "Museo Regional de Ica",
                    "description": "Un vistazo a la historia y cultura prehispánica de la región."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Ica",
            "content": "La cocina de Ica combina tradición criolla con dulces únicos. Prueba:",
            "list": [
              {
                "name": "Carapulcra con sopa seca",
                "description": "Un clásico peruano lleno de sabor."
              },
              {
                "name": "Picante de pallares",
                "description": "Tradición regional en cada bocado."
              },
              {
                "name": "Chocotejas",
                "description": "Dulces icónicos de Ica."
              },
              {
                "name": "Pisco sour",
                "description": "La bebida bandera del Perú, originaria de esta región."
              }
            ]
          },
          {
            "title": "Consejos para visitar Ica",
            "list": [
              {
                "name": "Ropa adecuada",
                "description": "Lleva prendas ligeras para el día y una chaqueta para las noches frescas del desierto."
              },
              {
                "name": "Protección solar",
                "description": "Usa bloqueador, gafas de sol y sombrero para protegerte del sol intenso."
              },
              {
                "name": "Planifica tus tours",
                "description": "Reserva actividades como tubulares y excursiones a Paracas con anticipación."
              },
              {
                "name": "Transporte seguro",
                "description": "Si visitas bodegas, considera contratar transporte para disfrutar sin preocupaciones."
              }
            ]
          },
          {
            "title": "¿Por qué visitar Ica?",
            "content": "Ica ofrece la mezcla perfecta de aventura, historia y sabores únicos. Desde recorrer las dunas de Huacachina hasta saborear el auténtico pisco, cada momento en esta región es inolvidable. \n\n¡Explora Ica, el corazón del desierto peruano! 🌞🍷"
          },
          {
            "title": "Islas Ballestas",
            "image":"/images/destinos/ica/islas-ballestas.jpg",
            "content": 'Las Islas Ballestas, conocidas como el "Galápagos peruano", te sorprenderán con su abundante fauna marina: pingüinos de Humboldt, lobos marinos y aves guaneras. ¡Embárcate en esta aventura y descubre la magia del Pacífico! 🐧',
            }
        ],

        "itinerary": [
            {
            "day": 1,
            "title": "Lima – Reserva Nacional de Paracas",
            "description": [
                "Salida temprana hacia Paracas (4 horas).",
                "Visita a la Reserva Nacional de Paracas: formaciones rocosas y playas Yumaque, Roja y La Mina.",
                "Tiempo libre para disfrutar del mar.",
                "Pernocte en Paracas."
            ]
            },
            {
            "day": 2,
            "title": "Islas Ballestas – Ica – Huacachina – Lima",
            "description": [
                "Desayuno y recorrido en deslizador por las Islas Ballestas: leones marinos, pingüinos y aves marinas.",
                "Visita a bodegas en Ica para degustar vinos, piscos y chocotejas.",
                "Exploración del Oasis de Huacachina.",
                "Parada opcional en Chincha y regreso a Lima por la noche."
            ]
            }
        ]
      
    };

      

    return (
        <TemplateB  data={content} place="Ica" title={content.title} canonicalUrl={url}  description={description} />
    )
}