import TemplateB from "../template_b";

export default function HuancayaPage(){
    const url = "https://www.dosgardeniastours.com/destinos/huancaya";
    const description = "Relájate en Huancaya, un paraíso andino de Perú con lagunas turquesas, senderos escénicos, platos tradicionales y recomendaciones para una escapada tranquila";
    
    const content = {
        "banner": "/images/destinos/huancaya/huancaya.jpg",
        "document": {
          "name": "Programa Huancaya",
          "pdf": "/pdf/destinos/programa-huancaya.pdf"
        },
        "title": "Huancaya: El Paraíso Escondido de los Andes Peruanos",
        "subtitle": "Situado en la región Lima, Huancaya es un tesoro natural dentro de la Reserva Paisajística Nor Yauyos-Cochas, conocida por sus lagunas turquesas, cascadas cristalinas y paisajes andinos. Un destino ideal para quienes buscan desconexión y aventura.",
        "sections": [
          {
            "title": "Mejor época para viajar a Huancaya",
            "content": "Mayo a noviembre: Temporada seca, ideal para explorar sus cascadas y lagunas.\nJulio y agosto: Clima fresco y estable, perfecto para vacaciones escolares.\nEvita enero a marzo: Las lluvias pueden dificultar el acceso."
          },
          {
            "title": "Lugares imperdibles en Huancaya",
            "subsections": [
              {
                "title": "Cascadas y lagunas",
                "image": "/images/destinos/huancaya/cascadas-del-amor.jpeg",
                "list": [
                  {
                    "name": "Cascadas del Amor",
                    "description": "Un rincón romántico rodeado de naturaleza."
                  },
                  {
                    "name": "Laguna Huallhua",
                    "description": "Famosa por su vibrante color turquesa."
                  },
                  {
                    "name": "Laguna Azul",
                    "description": "Perfecta para fotos y momentos de contemplación."
                  }
                ]
              },
              {
                "title": "Caminatas y trekking",
                "image": "/images/destinos/huancaya/ruta-cascadas.jpeg",
                "list": [
                  {
                    "name": "Ruta de las Cascadas",
                    "description": "Un sendero impresionante por paisajes naturales."
                  },
                  {
                    "name": "Pueblo de Vilca",
                    "description": "Ideal para conocer más sobre la vida andina."
                  }
                ]
              },
              {
                "title": "Cultura y tradiciones locales",
                "image": "/images/destinos/huancaya/campanario-huancaya.jpeg",
                "list": [
                  {
                    "name": "Iglesia Colonial de San Francisco de Asís",
                    "description": "Patrimonio cultural del pueblo."
                  },
                  {
                    "name": "Fiestas Patronales",
                    "description": "Una muestra de las tradiciones locales."
                  }
                ]
              },
              {
                "title": "Flora y fauna",
                "image": "/images/destinos/huancaya/lago.jpeg",
                "list": [
                  {
                    "name": "Animales andinos",
                    "description": "Flamencos, alpacas y vicuñas se dejan ver en los alrededores."
                  },
                  {
                    "name": "Flora autóctona",
                    "description": "Explora los bosques de queñuales y las puyas de Raimondi."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Huancaya",
            "content": "La cocina de Huancaya celebra los sabores andinos con:",
            "list": [
              {
                "name": "Trucha frita o al horno",
                "description": "Fresca y preparada al estilo tradicional."
              },
              {
                "name": "Carapulcra",
                "description": "Un clásico con un toque local."
              },
              {
                "name": "Chicha de jora",
                "description": "Bebida fermentada perfecta para acompañar cualquier comida."
              }
            ]
          },
          {
            "title": "Consejos para visitar Huancaya",
            "list": [
              {
                "name": "Aclimatación",
                "description": "Huaraz está a más de 3,000 m.s.n.m.; evita actividades intensas al llegar."
              },
              {
                "name": "Ropa adecuada",
                "description": "Lleva ropa abrigadora y cómoda para las excursiones."
              },
              {
                "name": "Protección solar",
                "description": "No olvides bloqueador y mantente hidratado."
              },
              {
                "name": "Guías locales",
                "description": "Es recomendable contratar expertos para seguridad."
              },
              {
                "name": "Reserva anticipada",
                "description": "Hospedajes y tours se llenan rápido en temporada alta."
              }
            ]
          },
          {
            "title": "¿Por qué visitar Huancaya?",
            "content": "Huancaya es un paraíso de naturaleza y aventura, con paisajes únicos y una paz inigualable. Disfruta de sus cascadas, lagunas y tradiciones locales. ¡Un destino que te dejará sin aliento!"
          },
          {
            "title": "Laguna Huallhua",
            "image": "/images/destinos/huancaya/laguna-huallhua.png",
            "content": "La Laguna Huallhua, con sus aguas turquesas rodeadas de cascadas y montañas, es uno de los paisajes más impresionantes de Huancaya. Un verdadero paraíso natural que te conectará con la esencia de los Andes."
          }
        ],
        "itinerary": [
          {
            "day": 1,
            "title": "Tour 09 Lagunas y Bosque del Amor",
            "description": [
              "Llegada a Huancaya y descanso. Visita a los pueblos de Vilca y Papacocha.",
              "Caminata por el Bosque del Amor y exploración de sus impresionantes paisajes."
            ]
          },
          {
            "day": 2,
            "title": "Ruta del Pescador",
            "description": [
              "Visita a las Cataratas de Carhuayno y la Laguna Huallhua.",
              "Recorrido por la necrópolis de Quimllo y visita a la laguna Piquecocha."
            ]
          }
        ],
   
      }
      
      

    return (
        <TemplateB data={content} place={"Huancaya"} title={content.title} canonicalUrl={url}  description={description} />
    )
}