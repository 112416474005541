import TemplateA from "../template_a"

export default function TarapotoPage(){
    const url = "https://www.dosgardeniastours.com/destinos/tarapoto"
    const description = "Adéntrate en Tarapoto, la puerta de entrada a la Amazonía peruana, con exuberantes cascadas, fauna exótica, deliciosos sabores selváticos y recomendaciones para un viaje único";

    const content = {
        "banner": "/images/destinos/tarapoto/tarapoto.jpg",
        "document": {
          "name": "Programa Tarapoto",
          "pdf": "/pdf/destinos/programa-tarapoto.pdf"
        },
        "title": "Tarapoto: Explora la Puerta de la Amazonía Peruana y la Ciudad de las Palmeras",
        "subtitle": "Tarapoto, conocida como la 'Ciudad de las Palmeras', es el destino ideal para explorar la selva peruana. Con paisajes tropicales, cultura amazónica y una gastronomía única, es perfecta para viajeros que buscan naturaleza, tradición y aventura.",
        "sections": [
          {
            "title": "Mejor época para visitar Tarapoto",
            "content": "Viaja entre mayo y octubre, temporada seca con clima ideal para actividades al aire libre. Evita diciembre a marzo, cuando las lluvias son más intensas."
          },
          {
            "title": "Qué hacer en Tarapoto",
            "subsections": [
              {
                "title": "Cataratas y paisajes",
                "image": "/images/destinos/tarapoto/cordillera-escalera.jpeg",
                "list": [
                  {
                    "name": "Catarata de Ahuashiyacu",
                    "description": "Refrescante y de fácil acceso."
                  },
                  {
                    "name": "Laguna Azul",
                    "description": "Ideal para paseos en bote y relajación."
                  },
                  {
                    "name": "Reserva Cordillera Escalera",
                    "description": "Naturaleza y biodiversidad únicas."
                  }
                ]
              },
              {
                "title": "Cultura y tradiciones",
                "image": "/images/destinos/tarapoto/castillo-lamas.jpeg",
                "list": [
                  {
                    "name": "Lamas",
                    "description": "Folklore y vistas espectaculares."
                  },
                  {
                    "name": "Museo Chanka",
                    "description": "Conoce la herencia indígena amazónica."
                  }
                ]
              }
             
            ]
          },
          {
            "title": "Gastronomía típica de Tarapoto",            
            "content":"La comida de Tarapoto es una fusión de sabores frescos y exóticos de la selva, con ingredientes autóctonos y especias locales.",
            "list": [
              {
                "name": "Juane y tacacho con cecina",
                "description": "Sabores icónicos de la selva.",
                "image":"/images/destinos/tarapoto/juane.jpg",
              },
              {
                "name": "Chaufa regional e inchicapi",
                "description": "Fusión y tradición en cada plato.",
                "image":"/images/destinos/tarapoto/chaufa.jpg"
              }
            ]
          },
          {
            "title": "Consejos para tu viaje",
            "list": [
              {
                "name": "Prepárate para el clima",
                "description": "Lleva ropa ligera y cómoda, pero no olvides un impermeable para las lluvias inesperadas"
              },
              {
                "name": "Salud",
                "description": "Asegúrate de estar vacunado contra la fiebre amarilla antes de viajar"
              },
              {
                "name": "Protección",
                "description": "Usa repelente de insectos y protector solar"
              },
              {
                "name": "Respeto por la naturaleza",
                "description": "No dejes basura y sigue las indicaciones en las áreas protegidas"
              }
            ]
          },
          {
            "title": "Descubre Tarapoto",
            "content": "Explora la selva, disfruta de su cultura viva y deléitate con su cocina. Tarapoto te espera con aventuras, tranquilidad y la magia de la Amazonía peruana. ¡Planifica tu viaje ahora!"
          },
          {
            "title": "Laguna Azul",
            "image": "/images/destinos/tarapoto/laguna-azul.jpg",
            "content": "La Laguna Azul, en Tarapoto, es un paraíso natural con aguas cristalinas rodeadas de selva y montañas. Perfecta para disfrutar de un día de relajación, paseo en bote y exploración de su biodiversidad ¡Una joya escondida en la Amazonía peruana! 🛶🐦"
          }
        ],
        "itinerary": [
          {
            "day": 1,
            "title": "Lamas",
            "description": [
              "Llegada a Tarapoto y traslado al hotel.",
              "Almuerzo.",
              "Visita a Lamas: Plaza de Armas, Museo Chanca (opcional), Castillo Medieval y Barrio Huayco.",
              "Regreso a Tarapoto para cena."
            ]
          },
          {
            "day": 2,
            "title": "Laguna Azul",
            "description": [
              "Traslado a Sauce para paseo en bote en Laguna Azul.",
              "Tiempo libre y almuerzo.",
              "Regreso a Tarapoto para cenar."
            ]
          },
          {
            "day": 3,
            "title": "Alto Mayo",
            "description": [
              "Visita al Orquideario de Moyobamba, Chacra Vieja y naciente Tioyacu.",
              "Almuerzo y regreso a Tarapoto para cenar."
            ]
          },
          {
            "day": 4,
            "title": "Salto de la Bruja",
            "description": [
              "Caminata a Salto de la Bruja, Cascada de la Plaza y Poza Azul.",
              "Almuerzo en San José y regreso a Tarapoto para cenar."
            ]
          },
          {
            "day": 5,
            "title": "Catarata Ahuashiyacu",
            "description": [
              "Visita al Mirador Cerro Escalera y Catarata Ahuashiyacu.",
              "Almuerzo y regreso a Tarapoto.",
              "Traslado al aeropuerto."
            ]
          }
        ],
        
      }
      

    return (
        <TemplateA data={content}  place={"Tarapoto"} title={content.title} canonicalUrl={url}  description={description} />
    )
}