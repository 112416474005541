import TemplateB from "../template_b";

export default function SelvaCentralPage(){
    const url = "https://www.dosgardeniastours.com/destinos/selva-central";
    const description = "Explora la Selva Central de Perú con destinos como Oxapampa, Satipo, La Merced y Chanchamayo. Disfruta de exuberantes paisajes, cascadas majestuosas, deliciosa gastronomía amazónica y consejos esenciales para una experiencia inolvidable en la selva"

    const content = {
        "banner": "/images/destinos/selva-central/selva-central.jpg",
        "document": {
          "name": "Programa Selva Central",
          "pdf": "/pdf/destinos/programa-selva-central.pdf"
        },
        "title": "Selva Central: Naturaleza, Cultura y Aventura en el Corazón del Perú",
        "subtitle": "La Selva Central es un paraíso tropical que combina biodiversidad, paisajes impresionantes y una riqueza cultural única. \n\nHogar de comunidades nativas, colonias austroalemanas y una exuberante naturaleza, este destino es ideal para los amantes de la aventura y la cultura.",
        "sections": [
          {
            "title": "Mejor época para visitar la Selva Central",
            "content": "De mayo a octubre: Temporada seca, ideal para explorar sin interrupciones.\n Evita de enero a marzo: Lluvias frecuentes pueden dificultar el acceso."
          },
          {
            "title": "Qué hacer en la Selva Central",
            "subsections": [
              {
                "title": "Explora Chanchamayo: El corazón de la selva",
                "image": "/images/destinos/selva-central/chanchamayo.jpg",
                "list": [
                  {
                    "name": "Catarata Bayoz y El Tirol",
                    "description": "Cascadas imperdibles."
                  },
                  {
                    "name": "Villa Rica",
                    "description": "Tierra del mejor café peruano."
                  }
                ]
              },
              {
                "title": "Oxapampa: Naturaleza y herencia cultural",
                "image": "/images/destinos/selva-central/oxapampa.jpeg",
                "list": [
                  {
                    "name": "Colonia austroalemana",
                    "description": "Historia y tradiciones únicas."
                  },
                  {
                    "name": "Parque Nacional Yanachaga Chemillén",
                    "description": "Refugio de biodiversidad."
                  },
                  {
                    "name": "Pozuzo",
                    "description": "Primer asentamiento austroalemán en el Perú."
                  }
                ]
              },
              {
                "title": "Satipo: Cultura viva",
                "image": "/images/destinos/selva-central/satipo.jpeg",
                "list": [
                  {
                    "name": "Comunidades asháninkas",
                    "description": "Conexión con tradiciones ancestrales."
                  },
                  {
                    "name": "Cataratas Tsomontonari y Meretari",
                    "description": "Naturaleza en su máxima expresión."
                  }
                ]
              },
              {
                "title": "La Merced y alrededores",
                "image": "/images/destinos/selva-central/pampa-hermosa.jpeg",
                "list": [
                  {
                    "name": "Reserva Pampa Hermosa",
                    "description": "Hogar de especies endémicas."
                  },
                  {
                    "name": "Puente Colgante Kimiri",
                    "description": "Un icono fotográfico."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía de la Selva Central",
            "content": "Disfruta de los sabores auténticos de la selva:",
            "list": [
              {
                "name": "Tacacho con cecina y juane",
                "description": "Platos emblemáticos."
              },
              {
                "name": "Patarashca",
                "description": "Pescado envuelto en hojas de bijao."
              },
              {
                "name": "Café de Villa Rica",
                "description": "Considerado el mejor del Perú."
              },
              {
                "name": "Masato",
                "description": "Bebida tradicional hecha de yuca fermentada."
              }
            ]
          },
          {
            "title": "Consejos para tu visita",
            "list": [
              {
                "name": "Lleva ropa adecuada",
                "description": "Ligera, impermeable y de manga larga."
              },
              {
                "name": "Protección solar e hidratación",
                "description": "Bloqueador y agua siempre a la mano."
              },
              {
                "name": "Consulta por vacunas",
                "description": "Especialmente la de fiebre amarilla."
              },
              {
                "name": "Respeto cultural",
                "description": "Pide permiso antes de fotografiar en comunidades nativas."
              }
            ]
          },
          {
            "title": "¿Por qué visitar la Selva Central?",
            "content": "Este destino combina aventura, cultura y biodiversidad. Desde las imponentes cataratas de Chanchamayo hasta las tradiciones únicas de Pozuzo, la Selva Central promete experiencias inolvidables para los viajeros en busca de conexión con la naturaleza y la cultura peruana. ¡Descubre por qué la Selva Central es un tesoro escondido del Perú! 🌿"
          },
          {
            "title": "Atractivo destacado: Catarata Bayoz",
            "image": "/images/destinos/selva-central/catarata-bayoz.jpg",
            "content": "La catarata Bayoz es una espectacular caída de agua de 65 metros, rodeada por una exuberante vegetación. \nEs un destino perfecto para quienes buscan naturaleza y aventura.¡Un rincón impresionante que te dejará sin aliento! 💦🌿"
          }
        ],
        "itinerary": [
          {
            "day": "Día de salida",
            "title": "Salida desde Lima hacia La Merced",
            "description": [
              "Pasaje no incluido."
            ]
          },
          {
            "day": 1,
            "title": "San Ramón - La Merced",
            "description": [
              "Llegada a La Merced.",
              "Caminata a la Catarata del Tirol y visita al Mariposario Zhaveta Yard.",
              "Disfruta de licores y helados artesanales.",
              "Finalizamos en el Mirador Gran Cruz de Chanchamayo.",
              "Noche en La Merced."
            ]
          },
          {
            "day": 2,
            "title": "Valle del Perené",
            "description": [
              "Exploramos el Valle del Perené: Puente Kimiri, Cataratas Velo de Novia y Bayoz.",
              "Visita a la Comunidad Asháninka y una planta de café.",
              "Regreso a La Merced."
            ]
          },
          {
            "day": 3,
            "title": "Oxapampa",
            "description": [
              "Viaje a Oxapampa: Museo Schlaefli, Chontabamba (bailes tiroleses), y degustación en el trapiche El Wharapo.",
              "City tour en Oxapampa.",
              "Noche en Oxapampa."
            ]
          },
          {
            "day": 4,
            "title": "Pozuzo",
            "description": [
              "Viaje a Pozuzo: Cataratas Rayantambo, Yulitunqui y recorrido por el Parque Nacional Yanachaga Chemillén.",
              "City tour por Pozuzo y visita a una fábrica de cerveza artesanal."
            ]
          }
        ],
        
      }
      
    
    return (

        <TemplateB data={content} place={"Selva Central"} title={content.title} canonicalUrl={url} description={description} />
    )
}