import TemplateB from "../template_b";

export default function CajamarcaPage(){
    const  url = "https://www.dosgardeniastours.com/destinos/cajamarca";
    const description = "Visita Cajamarca, la cuna de la historia peruana, con sus relajantes baños termales, iglesias barrocas, productos lácteos locales y consejos para un viaje completo";

    const content= {
        "document":{
            name:"Programa Cajarmarca",
            pdf:"/pdf/destinos/programa-cajamarca.pdf"
        },
        "banner": "/images/destinos/cajamarca/cajamarca.webp",
        "title": "Cajamarca: La Capital del Carnaval Peruano y los Baños del Inca",
        "subtitle": "Cajamarca, ubicada en el norte del Perú, es un destino turístico único que combina paisajes andinos, arquitectura colonial y tradiciones vivas.\n\n Conocida como la Capital del Carnaval Peruano, esta ciudad ofrece una experiencia auténtica que fusiona historia, cultura y naturaleza, siendo el lugar perfecto para los viajeros que buscan sumergirse en la riqueza cultural de la Sierra peruana.",
        "sections": [
          {
            "title": "Mejor época para viajar a Cajamarca",
            "content": "La mejor época para visitar Cajamarca es entre abril y septiembre, cuando las lluvias disminuyen y el cielo está más despejado. \n\nEste es el periodo ideal para explorar sus principales atracciones, como los Baños del Inca o el Cuarto del Rescate. \n Evita viajar de diciembre a marzo, cuando las lluvias pueden dificultar las actividades al aire libre.",
            "additional": "En febrero, la ciudad cobra vida con el Carnaval de Cajamarca, uno de los festivales más importantes y coloridos del Perú, que no te puedes perder."
          },
          {
            "title": "Qué hacer en Cajamarca",
            "subsections": [
              {
                "title": "Explora el Centro Histórico de Cajamarca",
                "image":"/images/destinos/cajamarca/cajamarca-catedral.jpeg",
                "list": [
                  {
                    "name": "Plaza de Armas de Cajamarca",
                    "description": "El punto de encuentro principal, rodeado de hermosas construcciones coloniales."                    
                  },
                  {
                    "name": "La Catedral",
                    "description": "Emblemático templo que refleja la arquitectura colonial de la ciudad."
                  },
                  {
                    "name": "Iglesia de San Francisco",
                    "description": "Un hermoso ejemplo de la fe y arte colonial en la región."
                  },
                  {
                    "name": "Cuarto del Rescate",
                    "description": "Monumento histórico donde el Inca Atahualpa fue retenido durante la conquista."
                  }
                ]
              },
              {
                "title": "Descubre maravillas naturales cerca de Cajamarca",
                "image":"/images/destinos/cajamarca/cajamarca-cumbe-mayo.jpeg",
                "list": [
                  {
                    "name": "Baños del Inca",
                    "description": "Termas históricas que ofrecen un baño relajante en aguas termales."
                  },
                  {
                    "name": "Ventanillas de Otuzco",
                    "description": "Impresionantes sepulturas talladas en las rocas de una montaña, con vistas panorámicas."
                  }
                ]
              },
              {
                "title": "Vive la aventura y paisajes andinos",
                "image":"/images/destinos/cajamarca/cajamarca-paisaje.jpeg",
                "list": [
                  {
                    "name": "Cumbemayo",
                    "description": "Un complejo arqueológico y natural con canales de agua tallados en la roca, rodeado de hermosos paisajes."
                  },
                  {
                    "name": "Granja Porcón",
                    "description": "Un proyecto agrícola y turístico en el que podrás interactuar con la naturaleza y la comunidad local."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Cajamarca",
            "content": "Cajamarca es un paraíso para los amantes de la gastronomía tradicional.",
            "list": [
              {
                "name": "Cuy frito",
                "description": "Plato emblemático de la región, preparado con cuy crujiente y sazonado."
              },
              {
                "name": "Caldo verde",
                "description": "Sopa espesa y sabrosa, ideal para los días fríos de la sierra."
              },
              {
                "name": "Manjar blanco",
                "description": "Un dulce tradicional hecho con leche y azúcar."
              },
              {
                "name": "Quesos artesanales",
                "description": "Cajamarca es famosa por sus quesos frescos y curados, un verdadero manjar."
              }
            ],
            "additional": "No olvides visitar los mercados locales para disfrutar de estos deliciosos platos y llevarte productos frescos y artesanías típicas de la región."
          },
          {
            "title": "Consejos para visitar Cajamarca",
            "list": [
              {
                "name": "Ropa adecuada",
                "description": "Lleva ropa abrigadora y resistente al agua, ya que las temperaturas pueden ser frescas y puede haber lluvias."
              },
              {
                "name": "Reserva anticipada",
                "description": "Si planeas visitar Cajamarca durante el Carnaval (febrero), reserva tu alojamiento con antelación, ya que la ciudad recibe a muchos turistas."
              },
              {
                "name": "Mercados y artesanías",
                "description": "Aprovecha para probar productos frescos y comprar artesanías típicas de la ciudad."
              },
              {
                "name": "Contrata un guía",
                "description": "Si planeas visitar Cumbemayo o las Ventanillas de Otuzco, es recomendable contratar un guía especializado para conocer mejor la historia y el contexto de estos lugares."
              }
            ]
          },
          {
            "title": "¿Por qué visitar Cajamarca?",
            "content": "Cajamarca es uno de los destinos turísticos más destacados del Perú, que combina perfectamente historia incaica, paisajes andinos y una gastronomía incomparable.\n\n Desde su vibrante Carnaval de Cajamarca hasta sus históricos Baños del Inca y monumentos culturales, esta ciudad ofrece una experiencia única y auténtica que no te puedes perder. ¡Planifica tu viaje ahora y descubre por qué Cajamarca es uno de los mejores destinos turísticos en la Sierra peruana!"
          }
        ],
        "itinerary":  [
            {
              "day": "1",
              "title": "City Tour, La Collpa y Llacanora",
              "description": [
                "Traslado al hotel y city tour: Plaza de Armas, Iglesia San Francisco, Santa Catalina, Santa Apolonia y Silla del Inca.",
                "Visita La Collpa para el “llamado de las vacas”, la laguna artificial y la capilla.",
                "Caminata a las cascadas de Llacanora (en temporada de lluvias)."
              ]
            },
            {
              "day": "2",
              "title": "Cumbe Mayo y Otuzco",
              "description": [
                "Explora Cumbe Mayo: mirador Bella Vista, bosque de piedras, petroglifos y el acueducto preincaico.",
                "En Otuzco: ventanillas, puente colgante, hortensias y fundo Los Alpes para degustar productos lácteos."
              ]
            },
            {
              "day": "3",
              "title": "Granja Porcón y Artesanías",
              "description": [
                "Visita Huambocancha para admirar artesanías en piedra.",
                "En la Granja Porcón: talleres de madera, tejidos, vicuñas y mini zoológico.",
                "Tiempo libre para compras.",
                "Traslado de regreso."
              ]
            }
          ],
          

      }
      
    return (
        <TemplateB  data={content} place={"Cajamarca"} title={content.title} canonicalUrl={url} description={description} />
    )
}