import TemplateA from "../template_a"

export default function PunoPage(){
    const url = "https://www.dosgardeniastours.com/destinos/puno"
    const description = "Descubre la magia de Puno, la capital folclórica de Perú, con el emblemático Lago Titicaca, las tradicionales islas flotantes de los Uros y consejos para este destino de gran altitud";

    const content = {
        "banner": "/images/destinos/puno/puno.jpg",
        "document": {
          "name": "Programa Puno",
          "pdf": "/pdf/destinos/programa-puno.pdf"
        },
        "title": "Puno: Capital del Folklore y Hogar del Lago Titicaca",
        "subtitle": "Puno, la \"Capital del Folklore Peruano\", es un destino mágico donde la cultura viva y los paisajes espectaculares se encuentran. \nUbicada a orillas del imponente Lago Titicaca, el lago navegable más alto del mundo, Puno ofrece una experiencia única que combina tradiciones ancestrales y naturaleza inigualable.",
        "sections": [
          {
            "title": "Mejor época para visitar Puno",
            "content": "Viaja entre mayo y octubre para disfrutar de cielos despejados y un clima ideal.\nEvita los meses lluviosos de noviembre a marzo, excepto en febrero, cuando la Festividad de la Virgen de la Candelaria llena de vida la ciudad."
          },
          {
            "title": "Qué hacer en Puno",
            "subsections": [
              {
                "title": "Explora el Lago Titicaca y sus islas",
                "image": "/images/destinos/puno/tour-uros.jpeg",
                "list": [
                  {
                    "name": "Islas flotantes de los Uros",
                    "description": "Comunidades únicas construidas sobre totora."
                  },
                  {
                    "name": "Isla Taquile",
                    "description": "Con vistas espectaculares y artesanías tradicionales."
                  },
                  {
                    "name": "Isla Amantaní",
                    "description": "Naturaleza y hospitalidad local."
                  }
                ]
              },
              {
                "title": "Cultura e historia en Puno",
                "image": "/images/destinos/puno/sillustani.webp",
                "list": [
                  {
                    "name": "Festividad de la Virgen de la Candelaria",
                    "description": "Declarada Patrimonio Cultural por la UNESCO."
                  },
                  {
                    "name": "Catedral de Puno y Parque Pino",
                    "description": "Emblemas del centro histórico."
                  },
                  {
                    "name": "Complejo de Sillustani",
                    "description": "Impresionantes torres funerarias junto a la laguna Umayo."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía puneña",
            "content": "Puno deleita con una cocina auténtica y sabrosa:",
            "list": [
              {
                "name": "Chairo",
                "description": "Tradicional sopa andina."
              },
              {
                "name": "Trucha frita y quinua atamalada",
                "description": "Sabores locales que no puedes perderte."
              },
              {
                "name": "K’usa y huatia",
                "description": "Platos auténticos de la región."
              }
            ]
          },
          {
            "title": "Consejos para tu viaje",
            "list": [
              {
                "name": "Aclimátate",
                "description": "Puno está a 3,800 m.s.n.m.; evita el soroche con descanso y mate de coca."
              },
              {
                "name": "Abrígate",
                "description": "Las noches son frías; lleva ropa térmica."
              },
              {
                "name": "Protege tu piel",
                "description": "Usa protector solar; el sol es más intenso en altura."
              },
              {
                "name": "Reserva con tiempo",
                "description": "Especialmente para tours y festividades populares."
              }
            ]
          },
          {
            "title": "Descubre Puno",
            "content": "Navega por el Lago Titicaca, sumérgete en la vibrante cultura andina y vive tradiciones como la Candelaria. Puno te espera con experiencias únicas que conectan con el corazón del Perú. ¡Planea tu aventura ahora y vive la magia del folklore peruano!"
          },
          {
            "title": "Isla Taquile",
            "image": "/images/destinos/puno/taquille.jpg",
            "content": "Isla Taquile, ubicada en el lago Titicaca, es un lugar de paz y belleza, conocido por su impresionante paisaje y sus tradiciones ancestrales. Aquí, los residentes aún practican un sistema de trabajo colectivo y producen textiles reconocidos por su calidad. \n\n¡Un destino perfecto para conectar con la cultura y la naturaleza! 🌊🧣"
          }
        ],
        "itinerary": [
          {
            "day": 1,
            "title": "Bienvenida y City Tour en Puno",
            "description": [
              "Llegada a Puno, traslado al hotel y city tour por la Plaza de Armas, Mercado local, Museo Dreyer, Cerrito de Huajsapata y mirador del Arco Deustua.",
              "Noche en Puno."
            ]
          },
          {
            "day": 2,
            "title": "Islas Flotantes de los Uros y Taquile",
            "description": [
              "Visita a las Islas Flotantes de los Uros y la isla de Taquile con su arte textil.",
              "Almuerzo típico y vistas del Lago Titicaca.",
              "Regreso a Puno."
            ]
          },
          {
            "day": 3,
            "title": "Sillustani y Lago Umayo",
            "description": [
              "Recorrido por las Chullpas de Sillustani y el Lago Umayo, un paisaje perfecto para fotos.",
              "Regreso a Puno y tarde libre."
            ]
          },
          {
            "day": 4,
            "title": "Corredor Aymara (Chucuito, Juli, Pomata)",
            "description": [
              "Exploración del Corredor Aymara: Chucuito (Templo de la Fertilidad), Juli (iglesias coloniales) y Pomata (templo de Santiago y vistas del Lago Titicaca).",
              "Regreso a Puno y traslado al aeropuerto."
            ]
          }
        ],
      
      }
      
      

    return (
        <TemplateA data={content} place={"Puno"} title={content.title} canonicalUrl={url} description={description} />
    )
}