import Footer from "../components/footer";
import Header, { HeaderContext } from "../components/header";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, TODOS } from "../components/utils";
import { home_text, button_text } from "../translation";
import { useCallback, useContext, useEffect, useState } from "react";
import getPromos from "../providers/promos";
import { useLocation } from "react-router-dom";
import getPromo from "../providers/promo";
import askChatAI from "../providers/chatai";
import ChatDialog from "../components/chat_dialog";
import { AppContext } from "../App";
import { Helmet } from "react-helmet";

function Home() {
	const location = useLocation();

	const { lang, setOpenCoti, setOpenInfo, setItem, setOpenSorry } =
		useContext(HeaderContext);
	const [promos, setPromos] = useState([]);
	const [question, setQuestion] = useState("");
	const [openIA, setOpenIA] = useState(false);

	const [messageIA, setMessageIA] = useState("");

	const { handleLoading } = useContext(AppContext);
	const fetchPromos = useCallback(async () => {
		try {
			const promosData = await getPromos(TODOS);
			setPromos(promosData);
			// if (promosData.length > 0) setItem(promosData[0]);
		} catch (error) {
			console.error("Failed to fetch promos:", error);
		}
	}, []);

	const fetchPromo = useCallback(
		async (promoId) => {
			try {
				const promoData = await getPromo(promoId);
				setItem(promoData);
				console.log("promoData", promoData);
				setOpenInfo(true);
			} catch (error) {
				// console.error("Failed to fetch promos:", error);
				setOpenSorry(true);
			}
		},
		[setItem, setOpenInfo, setOpenSorry]
	);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const promoValue = queryParams.get("promo");
		if (promoValue) {
			// Handle the promo value
			fetchPromo(promoValue);
		}
	}, [location.search, fetchPromo]);

	useEffect(() => {
		fetchPromos();
	}, [fetchPromos]);

	// const onPromoClick = (item) => {
	// 	setItem(item);
	// 	setOpenInfo(true);
	// };
	const onSubmitQuestion = (e) => {
		e.preventDefault();
		handleLoading(true);

		askChatAI(question).then((res) => {
			setQuestion("");
			handleLoading(false);
			setMessageIA(res);
			setOpenIA(true);
		});
	};
	return (
		<div id="home">
			<Header />
			<ChatDialog
				title={"¿No sabes a donde ir?"}
				onClose={() => setOpenIA(false)}
				isOpen={openIA}
				first_answer={messageIA}
			/>
			<Helmet>
				<title>Dos Gardenias Tours | Agencia de Viajes</title>
				<meta name="description" content="Agencia de viajes Dos Gardenias Tours" />
				<meta name="keywords" content="turismo,viaje,trip,tours,peru,tips, consejos, a donde viajar" />			
				<meta property="og:title" content="Dos Gardenias Tours - Tu Puerta a Vuelos Increíbles" />
				<meta property="og:description" content="Dos Gardenias Tours ofrece ofertas exclusivas de vuelos a destinos populares en todo el mundo. ¡Reserva tu próxima aventura con nosotros!" />
				<meta property="og:url" content="https://www.dosgardeniastours.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:locale" content="es_PE" />
				<meta property="og:image:alt" content="Dos Gardenias Tours - El viaje de tus sueños de espera" />

			</Helmet>
			<div className="">
				<div className="relative w-full h-fit">
					<div className="bg-yellow-300 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ">
						<img
							alt="promo-image"
							src="/images/home/center.png"
							className="hidden md:block bg-white h-auto max-h-[25vw] "
						/>
					</div>
					<div className="grid  grid-cols-1 md:grid-cols-2">
						<div className="hidden md:block">
							<img
								alt="promo-image"
								src="/images/home/left.png"
								className="w-full"
							/>
						</div>
						<div className="flex bg-dgblue px-10 py-20 md:p-0 ">
							<div className="md:pl-12 m-auto  flex flex-col items-center gap-y-8 text-white">
								<p
									dangerouslySetInnerHTML={{
										__html: home_text.main[lang],
									}}
									className="text-5xl md:text-4xl lg:text-5xl xl:text-7xl  font-ivymode  text-center md:text-left "
								/>

								<button
									onClick={() => setOpenCoti(true)}
									className="text-dggreen hover:text-dgtitle  md:mr-auto px-6 md:px-10  py-1 md:py-2 text-3xl bg-white rounded-3xl shadow-lg font-poppins hover:shadow-xl"
								>
									{button_text.coti[lang]}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="">
					<p className="text-3xl md:text-5xl my-14 text-center text-dgtitle font-ivymode">
						{home_text.promos[lang]}
					</p>
					<div className="px-4 md:px-32 font-poppins ">
						<Carousel
							infinite
							responsive={responsive}
							autoPlay
							arrows={true}
						>
							{promos.map((it, idx) => (
								<a
									target="_blank"
									rel="noreferrer"
									href={it.pdf}
									key={"key-" + idx}
									className="hover:cursor-pointer hover:shadow-lg"
								>
									<img
										alt="promo-image"
										src={it.img}
										className="m-auto h-48 md:h-auto md:max-h-fit px-2"
									/>
									<p className="text-md md:text-2xl font-poppins text-center mt-4">
										{it.name}
									</p>
								</a>
							))}
						</Carousel>
					</div>

					<div className="mx-4 px-10 lg:mx-20 lg:px-20 py-12 md:py-24 flex flex-wrap flex-col xl:flex-row justify-between">
						<div className="text-center md:text-left mb-10 xl:mb-0 ">
							<p className="font-ivymode text-2xl lg:text-3xl xl:text-4xl mb-2">
								{home_text.more_title[lang]}
							</p>
							<p className="font-poppins text-2xl">
								{home_text.more_subtitle[lang]}
							</p>
						</div>
						
						<div className="flex flex-col gap-y-12 md:gap-y-0  md:flex-row justify-between gap-x-10 items-center ">
							<div>
								<a
									href="/vuelos-nacionales"
									className="text-2xl  px-20 py-4 rounded-full bg-white text-dgblue shadow-custom-md hover:shadow-custom-lg"
								>
									{button_text.national[lang]}
								</a>
							</div>
							<div>
								<a
									href="/vuelos-internacionales"
									className="text-2xl w-60 px-14 py-4 rounded-full bg-white text-dgblue shadow-custom-md hover:shadow-custom-lg"
								>
									{button_text.international[lang]}
								</a>
							</div>
						</div>
					</div>
					<div
						id="chatgpt"
						className="px-6 py-8 md:px-10 xl:px-32 md:py-12  bg-dgwhite"
					>
						<div className="px-8 md:px-14 xl:px-24 py-10 md:py-20 shadow-md md:shadow-2xl  rounded-[3.5rem] md:rounded-[4.5rem] flex flex-col bg-white">
							<form onSubmit={onSubmitQuestion}>
								<p className="text-2xl md:text-4xl text-dgtitle font-ivymode">
									{home_text.chatgpt[lang]}
								</p>
								<input
									onChange={(e) => setQuestion(e.target.value)}
									value={question}
									className="hidden md:block text-lg md:text-lg xl:text-xl px-4 py-2 w-full border-2 border-dgtitle rounded-full my-4 text-black 								
									"
									placeholder={home_text.placeholder[lang]}
								/>
								<textarea
									onChange={(e) => setQuestion(e.target.value)}
									value={question}
									placeholder={home_text.placeholder[lang]}
									className="block md:hidden text-md md:text-xl px-4 py-2 w-full border-2 border-dgtitle rounded-3xl my-4 text-black 								
									"
								></textarea>
								<button
									type="submit"
									className="ml-auto bg-dgblue rounded-full px-8 py-1 text-lg text-white font-poppins w-full md:w-fit"
								>
									{button_text.ask[lang]}
								</button>
							</form>
						</div>
					</div>
					<div className="px-4 md:px-20 bg-dggray-100 flex flex-col py-16">
						<p
							className="text-2xl md:text-4xl text-center   text-dgtitle font-ivymode"
							dangerouslySetInnerHTML={{
								__html: home_text.company[lang],
							}}
						/>

						<div className="grid grid-cols-1 gap-y-6 md:gap-y-0  md:grid-cols-3 justify-between my-10 flex-wrap">
							<div className="flex flex-col gap-y-4">
								<p className="text-center text-xl lg:text-2xl mb-2 font-poppins">
									{home_text.certi_1[lang]}
								</p>
								<img
									alt="promo-image"
									src="/images/home/certis/iso.png"
									className="m-auto p-2 md:p-6 lg:p-0 h-40 lg:h-24 xl:h-32"
								/>
							</div>
							<div className="flex flex-col gap-y-4">
								<p className="text-center text-xl lg:text-2xl mb-2 font-poppins">
									{home_text.certi_2[lang]}
								</p>
								<img
									alt="promo-image"
									src="/images/home/certis/mincetur.png"
									className="m-auto p-2 md:p-3 lg:p-0 h-24 lg:h-20 xl:h-32"
								/>
							</div>
							<div className="flex flex-col gap-y-4">
								<p className="text-center text-xl lg:text-2xl mb-2 font-poppins">
									{home_text.certi_3[lang]}
								</p>
								<img
									alt="promo-image"
									src="/images/home/certis/peru.png"
									className="m-auto p-2 md:p-2 lg:p-0 h-24  lg:h-20 xl:h-32"
								/>
							</div>
						</div>
						<a
							href="/nosotros"
							className="border-2 px-8 py-2 text-dgtitle text-2xl m-auto rounded-full font-poppins border-dgtitle shadow-lg hover:bg-dgwhite hover:text-black "
						>
							{button_text.aboutus[lang]}
						</a>
					</div>

					<div id="vpromo-wedding" className="grid grid-cols-1 md:grid-cols-2  text-white">
						<div className="text-center bg-dgblue px-4  py-10 md:py-14 xl:py-20 ">
							<p className="text-3xl lg:text-4xl xl:text-5xl whitespace-pre font-ivymode mb-4">
								{home_text.school_1[lang]}
							</p>
							<p className="text-xl lg:text-xl xl:text-2xl whitespace-pre font-poppins mb-10">
								{home_text.school_2[lang]}
							</p>
							<a href="/viajes-promocion" className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white text-dggreen hover:text-dgblue text-2xl font-poppins">
								{button_text.seemore[lang]}
							</a>
						</div>

						<div className="text-center bg-dggray-400  px-4 py-10 md:py-14 xl:py-20  ">
							<p className="text-3xl lg:text-4xl xl:text-5xl mb-4 font-ivymode whitespace-pre">{home_text.wedding_1[lang]}</p>
							<p className="text-xl  lg:text-xl xl:text-2xl font-poppins whitespace-pre mb-10">{home_text.wedding_2[lang]}</p>
							<a href="/bodas" className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white text-dggreen hover:text-dgblue text-2xl font-poppins">
								{button_text.seemore[lang]}
							</a>
						</div>
					</div>


					
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Home;
