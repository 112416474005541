import InfoItem from "../components/info_item"
import TipItem from "../components/tip_item";
import { useScrollTo } from "../utils";

import Header from "../components/header";
import Footer from "../components/footer";
import Schedule from "../components/schedule";
import { REACT_APP_APP_NAME } from "../components/utils";
import { Helmet } from "react-helmet";

export default function TemplateA(props){
    const {data} = props;
    const place = props.place;
    const {title, canonicalUrl, description, } = props;
    

    const { elementRef, scrollToElement } = useScrollTo();
    
    const app_name = REACT_APP_APP_NAME;


    
    
    return (
        <>  
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href={canonicalUrl} />                
                <meta name="description" content={description} />
                <meta property="og:site_name" content="DosGardeniasTours" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:title" content={data.title} />
                <meta property="og:description" content={description} />              
            
            </Helmet>
            <Header />
            

            <div className="max-w-full font-poppins ">

                <div className="px-8 md:px-[8%] m-auto max-w-full xl:max-w-7xl py-16 ">
                    <h1 className="text-4xl text-center  w-full  xl:w-96 m-auto font-ivymode">{data.title}</h1>
                    <p className="text-xl  text-center py-10 whitespace-pre-line">{data.subtitle}</p>
                    <div className="flex justify-center gap-x-10 font-poppins flex-wrap gap-y-4">
                        <button className="px-4 py-2 rounded-lg bg-dgbrown-500 w-3/4  md:w-48 text-white hover:bg-dggreen" onClick={scrollToElement}>
                            VER ITINERARIO
                        </button>
                        <a target="_blank"
                            rel="noreferrer"
                            href={data.document.pdf}	
                            className="px-4 py-2 rounded-lg bg-dgbrown-500 w-3/4 md:w-48  text-white text-center hover:bg-dggreen">
                            DESCUBRE MÁS
                        </a>
                    </div>
                    <div className=" w-full xl:max-w-7xl flex justify-center">
                        <img alt={app_name+`-descubre-${place}`} src={data.banner} className="m-auto w-full md:w-auto max-w-screen-xl my-10 md:my-14 px-0 md:px-2 xl:px-0" />                    
                    </div>
                    <div className="text-center ">
                        <h2 className="text-2xl font-bold ">{data.sections[0].title}</h2>
                        <h3 className="whitespace-pre-line text-xl">{data.sections[0].content}</h3>
                    </div>
                    
                    <div className="flex flex-col gap-y-8 my-14">
                        <h2 className="text-2xl font-bold text-center">{data.sections[1].title}</h2>

                        {data.sections[1].subsections.map((it,idx)=>                         
                            <InfoItem place={place} data={it} key={`item-info-${idx}`} />
                        )}
                    </div>
                    <div ref={elementRef}></div>

                    <Schedule itinerary={data.itinerary} />
                    


                    <div id="food" className="my-10">
                        <h2 className="text-center font-bold text-2xl">{data.sections[2].title}</h2>
                        <h3 className="py-4 text-xl text-center whitespace-pre-line">{data.sections[2].content}</h3>                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-10 my-10">
                            {data.sections[2].list.map((it,idx) => <TipItem data={it} key={`item-${idx}`} /> ) }
                        </div>
                    </div>
                    {/* si no hay atractivo o actividad especial */}
                    {data.sections.length === 6 &&<div id="special" className="my-10">
                            <div className=" w-full xl:max-w-7xl flex justify-center flex-col">
                                <h2 className="text-3xl font-bold text-center ">{data.sections[5].title}</h2>
                                <img alt={app_name+`-descubre-${place}`} src={data.sections[5].image} className="m-auto w-3/4 max-w-screen-xl mt-4 mb-8 px-0 md:px-2 xl:px-0" />                    
                            </div>
                            <div className="text-center">
                                <h3 className="whitespace-pre-line text-xl">{data.sections[5].content}</h3>
                            </div>
                    </div>}

                    <div id="tips" className="">
                        <h2 className="text-center font-bold text-2xl ">{data.sections[3].title}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-10 my-10">
                            {data.sections[3].list.map((it,idx)=>                         
                                <TipItem place={place} data={it} key={`item-info-${idx}`} />
                            )}
                        </div>

                    </div>
                    <div id="discovery" className="my-10">
                        <h2 className="text-center font-bold text-2xl">{data.sections[4].title}</h2>
                        <h4 className="py-4 text-xl text-center whitespace-pre-line ">
                            {data.sections[4].content}
                        </h4>
                        <div className="flex gap-x-10 flex-wrap gap-y-4 justify-center my-6">
                            <a 
                                href={`https://wa.me/51917838795?text=${encodeURIComponent(`Hola, deseo más información sobre sus paquetes hacia ${place}`)}`}
                                target="_blank"
                                rel="noreferrer" className="text-center w-3/4 md:w-48 py-2 px-4 bg-dgtitle text-white rounded-lg hover:bg-dggreen" >RESERVA TU VIAJE</a>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={data.document.pdf}									
                                className="text-center w-3/4 md:w-48 py-2 px-4 bg-dgtitle text-white rounded-lg hover:bg-dggreen" 
                            >
                                    DESCUBRE MÁS
                            </a>
                                
                        </div>
                    </div>
                    


                </div>
            </div>
            <Footer />
        </>

    )
}